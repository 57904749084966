import axios from "@/api/entradaslima";
import { transformDate } from "@/utils/date";
import { b64toBlob } from "../utils/file";

const state = () => ({
  shoppingsList: [],
});

const getters = {
  getMyShoppingsList(state) {
    return state.shoppingsList;
  },
};

const mutations = {
  setShoppingsList(state, list) {
    state.shoppingsList = list;
  },
};

const actions = {
  async fetchMyShoppingsList({ commit, rootState }) {
    try {
      if (!rootState.auth.user) return;
      const idParque = 0; // 0 PARA QUE LISTE TODO rootState.currentDomainPark.IDPARQUE;
      const idUsuario = rootState.auth.user.IDUSUARIO;
      const endpoint = `/parques/${idParque}/compras/usuarios/${idUsuario}`;
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          idSistema: 1,
        },
      });
      if (status != 200) return null;
      const response = await data.data; //this.dispatch("decodeResponse", data.data);
      commit("setShoppingsList", response);
    } catch (error) {
      // console.log(error);
    }
  },
  async fetchPurchaseDetail(
    { state, rootState },
    { purchaseId, parkId = "", sendMail = 2, noUser }
  ) {
    try {
      if (!rootState.auth.user && !noUser) return null;
      if (!purchaseId) return null;

      const idParque = parkId; // para considerar la búsqueda solo por IDCOMPRA rootState.currentDomainPark.IDPARQUE;
      const idCompra = purchaseId;
      const endpoint = `/parques/${idParque}/compras/${idCompra}`
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          sendmail: sendMail || 2,
          idSistema: 1, // cambiar a 2
        },
      });

      if (status != 200) return null;

      const response = data.data; // await this.dispatch("decodeResponse", data.data);
      const acompaniantes = response.acompaniantes; /* await this.dispatch(
        "decodeResponse",
        data.acompaniantes
      ); */

      return {
        ...response,
        ACOMPANIANTES: response.acompaniantes,
        TICKETS: response.items,
      };
    } catch (error) {
      return null;
    }
  },
  async getPurchasePDFBase64({ rootState }, { idPdf, idPark, idCompra }) {
    try {
      const [,,,timestamp] = idPdf.split(".");
      const idParque = idPark || rootState.currentDomainPark.IDPARQUE;
      const idioma = rootState.lang;
      const payload = {
        idioma,
        idSistema: 1,
        fechahoraregistro: timestamp,
      }
      const endpoint = `/parques/${idParque}/compras/${idCompra}/file`;
      const { status, data } = await axios.post(endpoint, payload);
      if(status != 200 || !data.data) return null;
      return data.data;
    } catch (error) {
      return null;  
    }
  },
  async downloadPurchaseFile({ dispatch }, { idPdf, idPark = "", idCompra }) {
    try {
      if (!idCompra || !idPdf) return false;

      // Obtiene el pdf en b64
      const responseB64 = await dispatch("getPurchasePDFBase64", { idPdf, idPark, idCompra });

      if(!responseB64) return false;

      const { content, mimetype, extension } = responseB64;

      // Genera un blob
      const blob = b64toBlob(content, mimetype)
      const blobUrl = URL.createObjectURL(blob);

      // Descarga el pdf
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `${idCompra}.${extension}`;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        link.remove();
      }, 4000);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async validateEmailOrDocument(
    { state, rootState },
    { document = "", email = "", documentsList = [] }
  ) {
    try {
      // Parámetros
      const idCategoria =
        rootState.sale.finalPayload.category &&
        rootState.sale.finalPayload.category.IDCATEGORIA
          ? rootState.sale.finalPayload.category.IDCATEGORIA
          : 0;
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const acompaniantes = documentsList.map((doc) => ({
        tipoDocumento: parseFloat(doc.TIPO_DOCUMENTO),
        numeroDocumento: doc.DOCUMENTO,
      }));
      // Endpoint
      const endpoint = `/parques/${idParque}/categorias/${idCategoria}/validarunicoadquiriente`;
      // Consulta Servicio
      const { status, data } = await axios.post(endpoint, {
        correo: email,
        numeroDocumento: document,
        acompaniantes,
      });
      if (status != 200) return false;
      const value = data.data;

      if (value.CORREOADQUIRIENTE > 0) {
        this.dispatch("generateSnackbarError", {
          en:
            "Only one registration per user is allowed for this type of entry.",
          es:
            "Solo esta permitido un registro por usuario para este tipo de entradas.",
        });
        return false;
      } else if (value.DOCUMENTOADQUIRIENTE > 0) {
        this.dispatch("generateSnackbarError", {
          en:
            "Only an identity document record is allowed for this type of entry.",
          es:
            "Solo esta permitido un registro por documento de identidad para este tipo de entradas.",
        });
        return false;
      } else if (value.DOCUMENTOACOMPANIANTE > 0) {
        this.dispatch("generateSnackbarError", {
          en:
            "Some of the companions have already acquired tickets or are found as companions of other records.",
          es:
            "Alguno(s) de los acompañantes ya han adquirido entradas o se encuentran como acompañantes de otros registros.",
        });
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  },
  async getShoppingsListByDocumentAndDate(
    { state, rootState, dispatch },
    payload
  ) {
    try {
      const idParque = 0; // 0 para buscar en todos los parques rootState.currentDomainPark.IDPARQUE;
      const endpoint = `/parques/${idParque}/compras/busqueda`;
      const { data, status } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          idSistema: 1,
          tipoFecha: payload.iTipoFecha,
          fecha: transformDate({ date: payload.vFecha }),
          tipoDocumento: payload.iTipoDocumento,
          numeroDocumento: payload.vNumeroDocumento,
        },
      });
      if (status != 200) return [];
      const response = data.data; //await this.dispatch("decodeResponse", data.data);
      return response;
    } catch (error) {
      return [];
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
