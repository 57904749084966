import { encBtoa, decAtob } from "../libs/base64";

const state = () => ({
  menu: (slug = "") => [
    /*
    {
      name: {
        es: "Inicio",
        en: "Home",
      },
      icon: require("@/assets/img/icons/house.svg"),
      path: `/inicio`,
      visited: false,
      permitDisabled: false,
      extraClass: "",
    },
    */
    {
      name: {
        es: "Apertura de Caja",
        en: "Open register"
      },
      icon: require("@/assets/img/icons/calendario_menu.svg"),
      path: `/apertura-caja`, ///${slug}
      visited: false,
      permitDisabled: false,
      extraClass: ""
    },
    {
      name: {
        es: "Vender entradas",
        en: "Sale tickets"
      },
      icon: require("@/assets/img/icons/shopping_cart.svg"),
      path: `/ventas`,
      visited: false,
      permitDisabled: true,
      extraClass: ""
    },
    {
      name: {
        es: "Mis ventas",
        en: "My sales"
      },
      icon: require("@/assets/img/icons/ticket_menu.svg"),
      path: `/mis-ventas`, // /${slug}
      visited: false,
      permitDisabled: false,
      extraClass: ""
    },
    {
      name: {
        es: "Cierre de Caja",
        en: "Close register"
      },
      icon: require("@/assets/img/icons/calendario_menu.svg"),
      path: `/cierre-caja`, ///${slug}
      visited: false,
      permitDisabled: false,
      extraClass: ""
    }
    /*
    {
      name: {
        es: "Información del Evento",
        en: "Event Information"
      },
      icon: require("@/assets/img/icons/conocenos_menu.svg"),
      path: `/conocenos`,
      visited: false,
      permitDisabled: true,
      extraClass: ""
    },
    {
      name: {
        es: "Mi Perfíl",
        en: "My Profile"
      },
      icon: require("@/assets/img/icons/usuario.svg"),
      path: `/perfil`,
      visited: false,
      permitDisabled: false,
      extraClass: ""
    }
    */
  ]
});

const getters = {
  getNavigationMenu(state, rootState) {
    const park = rootState.getCurrentParkByDomain;

    // if (!park) return [];

    const saleVisibility = rootState.getVisibilitySaleView;
    const currentMenu = state.menu(park?.SLUG || "");

    if (park && park.CONOCENOS == 0) {
      const idx = currentMenu.findIndex(m => m.path == "/conocenos");
      if (idx > -1) {
        currentMenu.splice(idx, 1);
      }
    }

    if (park && !saleVisibility) {
      const idx = currentMenu.findIndex(m => m.path == "/");
      if (idx > -1) {
        currentMenu.splice(idx, 1);
      }
    }

    console.log(currentMenu);
    return currentMenu;
  }
};

const mutations = {
  setVisitedRoute(state, { routes, rootState }) {
    const park = rootState.getCurrentParkByDomain;
    if (!park) return;
    console.log("save visited route", park);
    const currentMenu = state.menu(park.SLUG);
    currentMenu.forEach(item => {
      if (routes.includes(item.path)) {
        item.visited = true;
      }
    });
    localStorage.setItem("history", encBtoa(JSON.stringify(routes)));
  }
};

const actions = {
  async getVisitedRoutes() {
    try {
      const encHistoryRoutes = localStorage.getItem("history");
      const historyRoutes = JSON.parse(decAtob(encHistoryRoutes));
      if (!historyRoutes || !Array.isArray(historyRoutes)) {
        return [];
      }
      return historyRoutes;
    } catch (error) {
      return [];
    }
  },
  async loadVisitedRoutes({ getters, rootState, commit, dispatch }) {
    const historyRoutes = await dispatch("getVisitedRoutes");
    commit("setVisitedRoute", { routes: historyRoutes, rootState });
  },
  async saveVisitedOnLs({ getters, rootState, commit, dispatch }, path) {
    try {
      const historyRoutes = await dispatch("getVisitedRoutes");
      if (historyRoutes.includes(path)) return;
      commit("setVisitedRoute", {
        routes: [...historyRoutes, path],
        rootState
      });
    } catch (error) {
      localStorage.removeItem("history");
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
