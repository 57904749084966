<template>
  <div class="lm--wrapper-content-app">
    <v-container>
      <TitleSection :title="titleSection" :subtitle="subtitleSection" noLogo />
    </v-container>
    <v-container>
      <v-row>
        <template v-if="parentsList && parentsList.length > 0">
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="12"
            v-for="(item, idx) in parentsList"
            :key="idx + 20"
          >
            <CardSite
              :logo="item.URLFOTOLOGO"
              :name="item.DESCRIPCION"
              :phrase="item.FRASE"
              :img="item.URLFOTO"
              :uriPay="item.SUBDOMINIO"
              :linkPhase="item.PADRE == '0' ? linkPhase : linkPhaseParentPark"
              :object="item"
            />
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleSection from "@/components/titles/TitleSection";
import CardSite from "@/components/cards/CardSite";
import { mapGetters, mapState } from "vuex";

export default {
  name: "Home",
  components: {
    TitleSection,
    CardSite
  },
  data: () => ({
    status: false
  }),
  computed: {
    ...mapGetters({
      parentsList: "getParents",
      lang: "getLanguage",
      accTools: "getAccesibilityTool"
    }),
    ...mapState({
      baseURL: state => state.baseURL,
      global: state => state.global,
      parksList: state => state.parkList,
      titleSection: state => state.titleSection.title[state.lang],
      subtitleSection: state => state.titleSection.subtitle[state.lang],
      linkPhaseParentPark: state => state.linkPhaseParentPark[state.lang],
      linkPhase: state => state.linkPhase[state.lang]
    })
  },
  mounted() {
    setTimeout(() => {
      this.accTools.setReadableElements(
        ".lm--left-content img, .lm--title, .copy, .lm--author, .lm--datalist-item, .lm--searcher"
      );
      this.accTools.restart(this.lang);
    }, 1000);
  }
};
</script>
<style lang="scss"></style>
