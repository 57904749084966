import Vue from "vue";
import VueRouter from "vue-router";
//import multiguard from 'vue-router-multiguard';
import { verifyDomain } from "@/libs/verifyDomain";
import { checkHistory } from "../middlewares/history";
import { clearPark, loadPark } from "../middlewares/park";
import store from "../store";
import {
  checkSessionTesorero,
  checkSessionBoletero,
} from "../middlewares/auth";
import multiguard from "vue-router-multiguard";

Vue.use(VueRouter);

const homeRouter = [
  // Oauth
  {
    path: "/auth/login",
    name: "LoginBoleteria",
    component: () => import("@/views/oauth/LoginBoleteria.vue"),
    meta: {
      authenticate: false,
    },
  },
  {
    path: "/auth/login/administrador",
    name: "LoginAdministrador",
    component: () => import("@/views/oauth/LoginAdmin.vue"),
    meta: {
      authenticate: false,
    },
  },
  // Mantenimiento
  {
    path: "/mantenimiento",
    name: "MatenimientoAdmin",
    component: () => import("@/views/mantenimiento/LayoutMantenimiento.vue"),
    // redirect: { name: 'MatenimientoBoleterias' },
    beforeEnter: checkSessionTesorero,
    meta: {
      authenticate: true,
    },
    children: [
      {
        path: "",
        name: "MatenimientoHome",
        component: () => import("@/views/mantenimiento/Home.vue"),
        meta: {
          authenticate: true,
        },
      },
      {
        path: "aperturacierre-boleterias",
        name: "MatenimientoEstadosBoleterias",
        component: () => import("@/views/mantenimiento/EstadosBoleterias.vue"),
        meta: {
          authenticate: true,
        },
      },
      {
        path: "asignacion-boleterias",
        name: "MatenimientoAsignacionBoleterias",
        component: () =>
          import("@/views/mantenimiento/AsignacionBoleteria.vue"),
        meta: {
          authenticate: true,
        },
      },
      {
        path: "asignacion-categorias",
        name: "MatenimientoAsignacionCategorias",
        component: () =>
          import("@/views/mantenimiento/AsignacionCategorias.vue"),
        meta: {
          authenticate: true,
        },
      },
      {
        path: "asignacion-articulos",
        name: "MatenimientoAsignacionArticulos",
        component: () =>
          import("@/views/mantenimiento/AsignacionArticulos.vue"),
        meta: {
          authenticate: true,
        },
      },
      {
        path: "boleterias",
        name: "MatenimientoBoleterias",
        component: () => import("@/views/mantenimiento/Boleterias.vue"),
        meta: {
          authenticate: true,
        },
      },
      {
        path: "ventas-boleterias",
        name: "VentasBoleterias",
        component: () => import("@/views/mantenimiento/VentasBoleterias.vue"),
        meta: {
          authenticate: true,
        },
      },
    ],
  },
  // Boletería
  {
    path: "/",
    name: "Park",
    component: () => import("@/views/subdomain/Home.vue"),
    beforeEnter: multiguard([checkSessionBoletero, loadPark]),
    meta: {
      authenticate: true,
    },
    children: [
      // Información del parque
      {
        path: "inicio",
        name: "HomeInfo",
        component: () => import("@/views/subdomain/ParkInfo.vue"),
        beforeEnter: checkHistory,
        meta: {
          authenticate: true,
        },
      },
      // Venta
      {
        path: "",
        name: "HomeSale",
        component: () => import("@/views/subdomain/SaleFlow.vue"),
        beforeEnter: checkHistory,
        redirect: { name: "HomeSaleV2" },
        meta: {
          authenticate: true,
        },
      },
      // About us / Conocenos
      {
        path: "conocenos",
        name: "AboutUs",
        component: () => import("@/views/subdomain/AboutUs.vue"),
        beforeEnter: checkHistory,
        meta: {
          authenticate: true,
        },
      },
      {
        path: "conocenos/:section",
        name: "SectionPage",
        component: () => import("../views/subdomain/Section.vue"),
        meta: {
          authenticate: true,
        },
      },
      {
        path: "conocenos/:section/:pin",
        name: "DataPinPage",
        component: () => import("../views/subdomain/Pin.vue"),
        meta: {
          authenticate: true,
        },
      },
      {
        path: "conocenos/:section/:pin/:id360",
        name: "Visor360Page",
        component: () => import("../views/subdomain/Visor360.vue"),
        meta: {
          authenticate: true,
        },
      },
      // Mis Entradas
      {
        path: "mis-ventas",
        name: "MyTickets",
        //component: () => import("@/views/subdomain/MyTickets.vue"),
        component: () => import("@/views/mantenimiento/VentasBoleterias.vue"),
        //beforeEnter: checkHistory,
        meta: {
          authenticate: true,
        },
      },
      // Detalle de Entrada / Resumen de compra
      {
        path: "mis-ventas/:parkId/:purchaseId",
        name: "TicketDetail",
        component: () => import("@/views/subdomain/TicketDetail.vue"),
        beforeEnter: (to, from, next) => {
          if (!from.name && to.query && to.query.visitDate) {
            next({ name: "SearchPage" });
            return;
          }
          next();
        },
        meta: {
          authenticate: true,
        },
      },
      // Ventas
      {
        path: "ventas",
        name: "HomeSaleV2",
        component: () => import("@/views/subdomain/SaleFlow.vue"),
        beforeEnter: checkHistory,
        meta: {
          authenticate: true,
        },
      },
      // Venta y Categoría
      {
        path: "ventas/:categoryId",
        name: "HomeCategorySale",
        component: () => import("@/views/subdomain/SaleFlow.vue"),
        beforeEnter: checkHistory,
        meta: {
          authenticate: true,
        },
      },
      // Perfil
      {
        path: "perfil",
        name: "Profile",
        component: () => import("@/views/subdomain/Account.vue"),
        beforeEnter: checkHistory,
      },
      // Cuadre de caja
      {
        path: "apertura-caja",
        name: "CashOpenRegister",
        component: () => import("@/views/boleteria/AperturaCaja.vue"),
        beforeEnter: checkHistory,
        meta: {
          authenticate: true,
        },
      },
      {
        path: "cierre-caja",
        name: "CashCloseRegister",
        component: () => import("@/views/boleteria/CierreCaja.vue"),
        beforeEnter: checkHistory,
        meta: {
          authenticate: true,
        },
      },
    ],
  },

  // Restablecer cuenta
  /*  {
    path: "/restablecer-cuenta",
    name: "RestoreAccount",
    component: () => import("@/views/RestorePassword.vue"),
  },
  // Activar cuenta
  {
    path: "/activar-cuenta",
    name: "ActivateAccount",
    component: () => import("@/views/ActivateAccount.vue"),
  },
  // Recuperar cuenta
  {
    path: "/recuperar-cuenta",
    name: "AccountRecovery",
    component: () => import("@/views/subdomain/RecoveryAccount.vue"),
  },
  // Perfil
  {
    path: "/perfil",
    name: "Profile",
    component: () => import("@/views/subdomain/Account.vue"),
    beforeEnter: checkHistory,
  },
  // Mis Entradas
  {
    path: "/mis-entradas",
    name: "MyTickets",
    component: () => import("@/views/subdomain/MyTickets.vue"),
    beforeEnter: checkHistory,
  },
  {
    path: "/mis-entradas/buscar",
    name: "SearchPage",
    component: () => import("@/views/subdomain/SearchPage.vue"),
  },
  // Detalle de Entrada / Resumen de compra
  {
    path: "/mis-entradas/:parkId/:purchaseId",
    name: "TicketDetail",
    component: () => import("@/views/subdomain/TicketDetail.vue"),
    beforeEnter: (to, from, next) => {
      if (!from.name && to.query && to.query.visitDate) {
        next({ name: "SearchPage" });
        return;
      }
      next();
    },
  },
  // Calendario
  {
    path: "/mi-calendario",
    name: "MyCalendar",
    component: () => import("@/views/subdomain/MyCalendar.vue"),
    beforeEnter: checkHistory,
  },
   */
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL || "/",
  routes: homeRouter,
});

router.beforeEach((to, from, next) => {
  const loginViews = ["LoginAdministrador", "LoginBoleteria"];
  const session = localStorage.getItem("session");
  if (loginViews.includes(to.name) && session) next("/");
  else if (to.meta?.authenticate && !session) next("/auth/login");
  else next();
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    if (store.state.isSubDomain) {
      const park = store.getters.getCurrentParkByDomain;
      const titlePage = `.: VENTAS EN LINEA - ${String(
        park.DESCRIPCION
      ).toUpperCase()} :.`;
      document.title = titlePage;
      gtag("config", window.GA_TRACKING_ID, {
        page_path: to.fullPath,
        app_name: titlePage,
        send_page_view: true,
      });
    } else {
      const titlePage = `.:${process.env.VUE_APP_BASE_NAME}:.`;
      document.title = titlePage;
    }
  });
});

export default router;
