import axios from "@/api/entradaslima";
import apiEntradasPHP from "@/api/pide";

const state = () => ({
  sections: [],
});

const getters = {
  getAboutSections(state) {
    return state.sections;
  },
};

const mutations = {
  setAboutSections(state, sections) {
    state.sections = sections;
  },
};

const actions = {
  async fetchAboutUsSections({ state, commit, rootState }) {
    try {
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const endpoint = `/parques/${idParque}/colecciones`;
      const { status, data } = await axios.get(endpoint, {
        params: {
          idSistema: 1,
          idioma: rootState.lang,
        },
      });
      if (status != 200) return;
      const response = data.data; //await this.dispatch("decodeResponse", data.data);
      commit("setAboutSections", response);
    } catch (error) {}
  },
  async fetchConsultCollections({ state, commit, rootState }, collectionId) {
    try {
      if (!collectionId) return null;
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const idColeccion = collectionId;
      const endpoint = `/parques/${idParque}/colecciones/${idColeccion}/marcadores`;
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          idSistema: 1,
        },
      });
      if (status != 200) return null;
      const response = data.data; //await this.dispatch("decodeResponse", data.data);
      return response;
    } catch (error) {
      return null;
    }
  },
  async fetchEventsList({ state, rootState }, iRutinario) {
    try {
      if (!iRutinario) return null;
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const endpoint = `/parques/${idParque}/eventos`;
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          idSistema: 1,
          rutina: iRutinario,
          // USUARIO
          /* accion: "consultarListadoEventos",
          iIdParque: rootState.currentDomainPark.IDPARQUE,
          iIdEvento: 0, */
        },
      });
      if (status != 200) return null;
      const response = data.data; //await this.dispatch("decodeResponse", data.data);
      return response;
    } catch (error) {
      return null;
    }
  },
  async fetchDataPIN({ state, rootState, dispatch }, { idPin, idColeccion }) {
    try {
      if (!idPin || !idColeccion) return null;
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const endpoint = `/parques/${idParque}/colecciones/${idColeccion}/marcadores/${idPin}`;
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          idSistema: 1,
        },
      });
      if (status != 200) return null;
      const response = await data.data; //this.dispatch("decodeResponse", data.data);
      if (Array.isArray(response) && response.length > 0) {
        return response[0];
      }
      return null;
    } catch (error) {
      return null;
    }
  },
  async fetchPinGallery(
    { state, rootState, dispatch },
    { idPin, idColeccion }
  ) {
    try {
      if (!idPin) return [];
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const endpoint = `/parques/${idParque}/colecciones/${idColeccion}/marcadores/${idPin}/galeria`;
      const { status, data } = await axios.get(endpoint, {
        params: {
          iidSistema: 1,
          idioma: rootState.lang,
        },
      });
      if (status != 200) return [];
      const response = data.data; //await this.dispatch("decodeResponse", data.data);
      return response;
    } catch (error) {
      return [];
    }
  },
  async fetchVisor360(
    { state, rootState, dispatch },
    { pinId = "", galleryId = "" }
  ) {
    try {
      if (!pinId || !galleryId) return null;
      const { status, data } = await apiEntradasPHP.get("/", {
        params: {
          accion: "consultarVisor360",
          iidSistema: 1,
          IDIOMA: rootState.lang,
          // USUARIO
          IDPARQUE: rootState.currentDomainPark.IDPARQUE,
          IDPIN: pinId,
          IDGALERIA: galleryId,
        },
      });
      if (status != 200) return null;
      return data;
    } catch (error) {
      return null;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
