import store from "../store";

export const loadPark = async (to, from, next) => {
  console.log("Entrando a middleware");
  // const { park_slug } = to.params;
  const parkId = store.getters.getSessionParqueId;
  if(!parkId) {
    store.dispatch("logout");
    return next("/auth/login");
  }
  store.commit("activeLoader", true);
  await store.dispatch("getParkByDomain", parkId);
  await store.dispatch("destroyAdvance");
  // await store.dispatch("fetchMyShoppingsList");
  store.commit("setStatusSubDomain", true);
  store.commit("activeLoader", false);
  next();
};


export const clearPark = async (to, from, next) => {
  store.commit("setCurrentDomainPark", null);
  await store.dispatch("destroyAdvance");
  console.log("limpiando parque");
  store.commit("setStatusSubDomain", false);
  next();
};