<template>
  <div class="app-auth">
    <div class="auth-menu">
      <v-menu
        id="authMenu"
        color="white"
        class="auth-menu"
        ref="authMenu"
        offset-y
        left
        :value="menu"
        :close-on-click="false"
        :close-on-content-click="false"
        content-class="auth-menu-content"
        :width="user ? '270' : '400'"
        elevation="0"
      >
        <template v-slot:activator="{ on, attrs }">
          <button
            v-if="user"
            class="dropdown-user pl-2"
            ref="btnOpenCloseMenu"
            v-bind="attrs"
            v-on="on"
            @click="toggleMenu(attrs)"
          >
            <img
              :src="
                user && user.URLFOTO
                  ? user.URLFOTO
                  : 'https://image.freepik.com/vector-gratis/perfil-avatar-hombre-icono-redondo_24640-14044.jpg'
              "
              alt=""
            />
            <span>
              <i class="fas fa-angle-down text-white c-white"></i>
            </span>
          </button>
          <button
            v-else
            class="dropdown-user d-flex align-items-center pl-1 pr-2"
            v-bind="attrs"
            v-on="on"
            @click="toggleMenu(attrs)"
          >
            <span>
              <i class="fas fa-user c-white"></i>
            </span>
            <p class="c-white mb-0 pl-0 p-font fw-700 text-medium">LOGIN</p>
          </button>
        </template>
        <v-card
          class="card-auth p-relative"
          :width="user ? '270' : '400'"
          elevation="0"
        >
          <div></div>
          <button
            @click="clickToCloseMenu"
            class="btn-close-menu"
            type="button"
          >
            <span class="material-icons">
              cancel
            </span>
          </button>
          <AuthInfo
            v-if="user && isLoggedIn"
            :user="user"
            @closeMenu="onCloseMenu"
          />
          <template v-else>
            <!-- <v-tabs
              v-model="tabMenuLocal"
              class="mt-4"
              centered
              @change="handleTabChange"
            >
              <v-tab :key="'tab-login'">{{ titles.login[lang] }}</v-tab>
              <v-tab :key="'tab-register'">{{ titles.register[lang] }}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabMenu">
              <v-tab-item value="tab-login" :key="1540">
                <FormLogin
                  @goingToRegister="
                    $store.dispatch('changeTabMenu', 'tab-register')
                  "
                ></FormLogin>
              </v-tab-item>
              <v-tab-item value="tab-register" :key="1541">
                <FormRegister
                  @uploadToError="uploadToError"
                  @goingToLogin="$store.dispatch('changeTabMenu', 'tab-login')"
                ></FormRegister>
              </v-tab-item>
            </v-tabs-items> -->
          </template>
        </v-card>
      </v-menu>
    </div>
    <!-- Dialog Login -->
    <!-- <v-dialog :width="'800'" scrollable v-model="dialog">
      <v-card>
        <v-tabs-items v-model="tabMenu">
          <v-tab-item value="tab-login" :key="1540">
            <FormLogin
              @goingToRegister="
                $store.dispatch('changeTabMenu', 'tab-register')
              "
            ></FormLogin>
          </v-tab-item>
          <v-tab-item value="tab-register" :key="1541">
            <FormRegister
              @uploadToError="uploadToError"
              @goingToLogin="$store.dispatch('changeTabMenu', 'tab-login')"
            ></FormRegister>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import AuthInfo from "@/components/AuthInfo";
// import FormLogin from "@/components/forms/FormLogin";
// import FormRegister from "@/components/forms/FormRegister";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    AuthInfo,
    // FormLogin,
    // FormRegister,
  },
  data() {
    return {
      localMenu: false,
      tabMenuLocal: "tab-login",
      tabsValue: ["tab-login", "tab-register"],
      titles: {
        login: {
          en: "Login",
          es: "Ingresar",
        },
        register: {
          en: "Register",
          es: "Registro",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "getSessionUser",
      menu: "getAuthMenu",
      tabMenu: "getTabMenu",
      isLoggedIn: "isLoggedIn",
      lang: "getLanguage",
    }),
  },
  watch: {
    tabMenu(val) {
      const idx = this.tabsValue.indexOf(val)
      this.tabMenuLocal = idx;
    },
  },
  methods: {
    ...mapActions({
      changeAuthMenu: "changeAuthMenu",
    }),
    handleTabChange(ev) {
      this.$store.dispatch("changeTabMenu", this.tabsValue[ev]);
    },
    toggleMenu(val) {
      if (val["aria-expanded"] == "true") {
        this.changeAuthMenu(false);
      } else {
        this.changeAuthMenu(true);
      }
    },
    uploadToError() {
      try {
        document
          .querySelector(".v-menu__content")
          .scrollTo({ left: 0, top: 0, behavior: "smooth" });
      } catch (error) {}
    },
    onCloseMenu() {
      this.changeAuthMenu(false);
      this.$router.push({ name: "Profile" }).catch((err) => {});
    },
    clickToCloseMenu() {
      this.changeAuthMenu(false);
    },
    getMenuValue(attrs) {},
  },
};
</script>

<style lang="scss">
.app-auth {
  margin-left: 15px;
}
.dropdown-user {
  height: 57px !important;
  display: flex;
  align-items: center;
  padding-right: 5px;
}
.dropdown-user img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.dropdown-user span {
  padding: 4px 10px 4px 8px;
  border-radius: 0 15px 15px 0;
  display: block;
  height: 30px;
  //background: var(--last-color);
}
.card-auth {
  border-top-color: var(--color-gray-variant);
  border-top-width: 1px;
  border-top-style: solid;
  position: relative;

  .btn-close-menu {
    display: none;
    position: absolute;
    top: 5px;
    right: 12px;
    align-items: center;
    z-index: 10;
    @media screen and (max-width: 600px) {
      display: inline-flex;
    }
  }
}
.app-auth {
  display: flex;
  align-items: center;
}
.auth-info {
  display: flex;
  flex-flow: column nowrap;
}
.auth-info .info-user {
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  padding: 0.8em;
}
.auth-info .info-user img {
  width: 28px;
  height: 28px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}
.auth-info .info-user .user h6 {
  margin-bottom: 0;
  font-size: 15px;
  min-width: 150px;
  max-width: 180px;
}
.auth-info .info-user .user small {
  max-width: 180px;
  margin-top: 0;
  font-size: 12px;
}
.auth-info ul {
  list-style: none;
  margin: 0;
  padding: 0.5em 0;
}
.auth-info ul li {
  list-style: none;
  width: 100%;
}
.auth-info ul li a,
.auth-info ul li button {
  display: block;
  text-decoration: none;
  width: 100%;
  padding: 0.8em 0;
  padding: 0.5em 1em;
  transition: all ease-in-out 0.1s;
  text-align: left;
  color: #666;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
}
.auth-info ul li a {
  font-size: 13px;
}
.auth-info ul li a:hover,
.auth-info ul li button:hover {
  background: #eee;
}

.auth-info ul li a i,
.auth-info ul li button i {
  margin-right: 10px;
}

.link-auth-menu:hover {
  small,
  span,
  i {
    color: var(--color-blue);
  }
}

.btn-list {
  padding: 8px !important;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.btn-menu {
  padding: 10px 12px !important;
  border-radius: 6px;
  background: var(--color-pink) !important;
  color: #fff !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-size: 14px !important;
}

.dropdown-user[aria-expanded="true"] {
  background: var(--color-blue);
  border-radius: 3px 3px 0 0;
  p {
    color: var(--color-yellow) !important;
  }
}

.auth-menu-content {
  border-radius: 0 !important;
  @media screen and (max-width: 500px) {
    left: 0 !important;
    .card-auth {
      border-radius: 0 !important;
    }
  }
}
</style>
