import store from "../store";

export const checkSessionBoletero = async (to, from, next) => {
  try {
    console.log("checkSessionBoletero");
    const session = localStorage.getItem("session");
    if (!session) throw new Error("Unauthorized");
    const user = await store.dispatch("getUserFromLS");
    if (!user) throw new Error("Unauthorized");
    const isBoletero = await store.dispatch("esBoletero", user);
    if (isBoletero) next();
    else next("/mantenimiento");
  } catch (error) {
    localStorage.clear();
    next("/auth/login");
  }
};

export const checkSessionTesorero = async (to, from, next) => {
  try {
    console.log("checkSessionTesorero");
    const session = localStorage.getItem("session");
    if (!session) throw new Error("Unauthorized");
    const user = await store.dispatch("getUserFromLS");
    if (!user) throw new Error("Unauthorized");
    const isTesorero = await store.dispatch("esTesorero", user);
    console.log({isTesorero});
    if (isTesorero) next();
    else next("/");
  } catch (error) {
    localStorage.clear();
    next("/auth/login");
  }
};
