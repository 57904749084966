import api from "@/api/entradaslima";

export const state = () => ({
  menu: [
    {
      title: "Asignación de Boleterías",
      route: "/mantenimiento/asignacion-boleterias",
      icon: "mdi-clipboard-account-outline"
    },
    {
      title: "Apertura y Cierre de Boleterías",
      route: "/mantenimiento/aperturacierre-boleterias",
      icon: "mdi-domain"
    },
    {
      title: "Ventas por Boletería",
      route: "/mantenimiento/ventas-boleterias",
      icon: "mdi-domain"
    },
    {
      title: "Asignación de Categorias",
      route: "/mantenimiento/asignacion-categorias",
      icon: "mdi-list-box-outline"
    },
    {
      title: "Asignación de Artículos",
      route: "/mantenimiento/asignacion-articulos",
      icon: "mdi-list-box-outline"
    },
    {
      title: "Boleterías",
      route: "/mantenimiento/boleterias",
      icon: "mdi-domain"
    }
  ]
});

export const getters = {
  getMenuMantenimiento(state) {
    return state.menu;
  }
};

export const mutations = {};

export const actions = {};

export default {
  state,
  getters,
  mutations,
  actions
};
